const productCategory = [

    { id : 1, label : "Personal Care", value : "personal care"},
    { id : 2, label : "Home Care", value : "home care"},
    { id : 3, label : "Medicines", value : "medicines"},
    { id : 4, label : "Fruits", value : "fruits"},
    { id : 5, label : "Beauty", value : "beauty"},
    { id : 6, label : "Stationary", value : "stationary"},
    { id : 7, label : "Electronics", value : "electronics"},
    { id : 8, label : "Home Decor", value : "home decor"},
    { id : 9, label : "Groceries", value : "groceries"},
]




export default productCategory